import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Heading from 'src/components/heading/Heading'
import StatusItemSmall from 'src/components/pages/status/StatusItemSmall'

// Main component:
const StatusListPast = ({ items, itemsUk, assets, data, isUk = false }) => {
  const [showAll, setShowAll] = useState(false)

  /* prevent from crashing if there are no items */
  const hasItems = isUk ? !!itemsUk.length : !!items.length

  /* on button click, show all past statuses */
  const handleShowAll = () => setShowAll(true)

  /* Return content */
  return (
    hasItems && (
      <>
        <Heading type="h2" page="status" section="past" className="x-h2" />
        <span className="x-past-status__desc">{data.description}</span>

        {isUk
          ? itemsUk.slice(0, 3).map((item, i) => {
              return <StatusItemSmall key={i} data={item} assets={assets} />
            })
          : items.slice(0, 3).map((item, i) => {
              return <StatusItemSmall key={i} data={item} assets={assets} />
            })}

        {showAll ? (
          <div className="collapse show">
            {isUk
              ? itemsUk.slice(3).map((item, i) => {
                  return <StatusItemSmall key={i} data={item} assets={assets} />
                })
              : items.slice(3).map((item, i) => {
                  return <StatusItemSmall key={i} data={item} assets={assets} />
                })}
          </div>
        ) : (
          <div className="x-asset-status__view-all" id="x-asset-status__view-all">
            <button className="x__button x__button--secondary" onClick={handleShowAll}>
              <span className="x__button-align">
                <span className="x__button-align-text">{data.viewMore}</span>
              </span>
            </button>
          </div>
        )}
      </>
    )
  )
}

// Main component props:
StatusListPast.propTypes = {
  items: PropTypes.array,
  assets: PropTypes.array,
  data: PropTypes.object,
}

StatusListPast.defaultProps = {}

export default StatusListPast
