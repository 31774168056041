// dependencies.
import React, { useState, useEffect } from 'react'
import { isEmpty, findIndex } from 'lodash'
import { format, addMinutes } from 'date-fns'
// components.
import PageHead from 'src/components/head/PageHead'
import { Header, Footer } from 'src/components/layout'
import {
  ContentSection,
  ErrorContent,
  HeaderSection,
  LoadingContent,
} from 'src/components/pages/status'
// utils.
import { commaSeparate } from 'src/utils'
import { useAssets } from 'js/utils/hooks/useAssetsBase'
import { useScrollToAnchor } from 'js/utils/hooks'
import fetchExchangePairs from 'src/js/status/fetch-exchange-pairs'
import fetchManuallySetData from 'src/js/status/fetch-manually-set-data'
import processExchangeData from 'src/js/status/process-exchange-data'
import useFetchAssets from 'js/assets/hooks/useFetchAssets'
// constants.
import { SHOW_AUTO_STATUS_MESSAGES } from 'src/constants'
// data.
import StatusData from 'src/data/pages/Contents/status.json'

// Styles & Images
import 'static/status/scss/styles.scss'

const statusTemplates = StatusData.assets.statusTemplates

// Helpers:
const now = new Date()
const utcDate = addMinutes(now, now.getTimezoneOffset())
const getFormattedDate = () => format(utcDate, 'MMMM d, yyyy')
const getFormattedTime = () => format(utcDate, "EEEE @ h:mm a 'UTC'")

// Main component:
const StatusPage = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  // fetch asset list.
  const { combinedAssets, count } = useFetchAssets()

  // data from Exodus servers.
  const [exchangePairs, setExchangePairs] = useState()

  // set by CSEs through Helpscout.
  const [statusMessagesManual, setStatusMessagesManual] = useState()
  const [pastStatusMessagesManual, setPastStatusMessagesManual] = useState()
  const [assetStatusesManual, setAssetStatusesManual] = useState()

  // to be modified based on fetched data.
  const [assetsData, setAssetsData] = useState(combinedAssets)
  const [statusMessagesAuto, setStatusMessagesAuto] = useState()
  const [exchangeDisabledInJurisdiction, setExchangeDisabledInJurisdiction] = useState(false)

  // show loading state if trying to fetch status.
  const [loaded, setLoaded] = useState(false)
  const [showError, setShowError] = useState(false)

  const assetsBase = useAssets()
  // fetch data.
  useEffect(() => {
    // manuallySetData
    const fetchManuallySetDataFunc = async () => {
      const { statusMessagesManual, pastStatusMessagesManual, assetStatusesManual, err } =
        await fetchManuallySetData(assetsBase)
      if (err) return setShowError(true)
      setStatusMessagesManual(statusMessagesManual)
      setPastStatusMessagesManual(pastStatusMessagesManual)
      setAssetStatusesManual(assetStatusesManual)
    }
    fetchManuallySetDataFunc()

    // exchangePairs
    const fetchExchangePairsFunc = async () => {
      const { exchangePairs, err } = await fetchExchangePairs()
      if (err) return setShowError(true)
      setExchangePairs(exchangePairs)
    }
    fetchExchangePairsFunc()
  }, [])

  // parse and prepare data.
  useEffect(() => {
    if (exchangePairs && assetStatusesManual && statusMessagesManual) {
      try {
        /* update assetsData */
        const assetsDataEnriched = [...assetsData]

        const { exchangeDisabledInJurisdiction, exchangeData, autoMessagesData } =
          processExchangeData({
            pairs: exchangePairs,
            assets: assetsDataEnriched,
          })

        setExchangeDisabledInJurisdiction(exchangeDisabledInJurisdiction)

        for (const [exchangeSymbol, exchangeStatus] of Object.entries(exchangeData)) {
          let assetIndex = findIndex(
            assetsDataEnriched,
            (asset) => asset.exchangeSymbol === exchangeSymbol
          )
          if (assetIndex === -1) {
            assetIndex = findIndex(assetsDataEnriched, (asset) => asset.symbol === exchangeSymbol)
          }

          assetsDataEnriched[assetIndex].functionality = { exchange: exchangeStatus }
          assetsDataEnriched[assetIndex].details = statusTemplates.available.details // exchangeStatus.details (TEMP OVERRIDE)
        }

        for (const assetStatus of assetStatusesManual) {
          if (exchangeDisabledInJurisdiction && assetStatus.func === 'exchange') continue

          let assetIndex = findIndex(
            assetsDataEnriched,
            (asset) => asset.exchangeSymbol === assetStatus.symbol
          )
          if (assetIndex === -1) {
            assetIndex = findIndex(
              assetsDataEnriched,
              (asset) => asset.symbol === assetStatus.symbol
            )
          }
          if (assetIndex === -1) continue

          if (!assetsDataEnriched[assetIndex].functionality)
            assetsDataEnriched[assetIndex].functionality = {}

          assetsDataEnriched[assetIndex].functionality[assetStatus.func] = assetStatus
          assetsDataEnriched[assetIndex].details = assetStatus.details
        }

        setAssetsData(assetsDataEnriched)

        /* auto status messages */
        const statusMessagesAuto = []

        if (exchangeDisabledInJurisdiction) {
          statusMessagesAuto.push({
            description: StatusData.status.exchangeDisabledInJurisdiction.description,
            issues: true,
          })
        } else if (SHOW_AUTO_STATUS_MESSAGES) {
          const { limited, unavailable } = autoMessagesData

          const getMessageBase = () => ({
            issues: true,
            date: getFormattedDate(),
            dateExpand: getFormattedTime(),
            aboutExchange: true,
          })

          const assetStringFromAsset = ({ name, symbol, networkSymbol }) =>
            `${name} (${symbol})${networkSymbol ? ` on the ${networkSymbol} Network` : ''}`

          if (!isEmpty(limited)) {
            statusMessagesAuto.push({
              ...getMessageBase(),
              subheading: StatusData.status.exchangeLimited.subheading,
              description: `${commaSeparate(limited.map(assetStringFromAsset))} ${
                limited.length === 1 ? 'has' : 'have'
              } limited exchange availability. ${StatusData.status.exchangeLimited.description}`,
            })
          }

          if (!isEmpty(unavailable)) {
            statusMessagesAuto.push({
              ...getMessageBase(),
              subheading: StatusData.status.exchangeUnavailable.subheading,
              description: `${commaSeparate(unavailable.map(assetStringFromAsset))} ${
                unavailable.length === 1 ? 'is' : 'are'
              } not available for exchange.  ${StatusData.status.exchangeUnavailable.description}`,
            })
          }
        }

        setStatusMessagesAuto(statusMessagesAuto)

        // Do not show exchange-related messages to folks in jurisdictions where exchanging is entirely disabled
        if (exchangeDisabledInJurisdiction) {
          setStatusMessagesManual(statusMessagesManual.filter((el) => !el.aboutExchange))
        }

        /* done, let's render */
        setLoaded(true)
      } catch (err) {
        console.warn('Failed to parse and prepare data:', err)
        setShowError(true)
      }
    }
  }, [exchangePairs, assetStatusesManual, statusMessagesManual])

  // if loaded and location has hash, scroll to anchor.
  useScrollToAnchor(loaded)

  // show the right tab.
  useEffect(() => {
    const activeTabByHash = window.location.hash.substring(1)
    try {
      if (activeTabByHash === 'crypto-status') setActiveTabIndex(1)
      else setActiveTabIndex(0) // status/ || status#supported-assets || status#assets
    } catch (err) {}
  }, [])

  return (
    <div className="x-page-status">
      <main className="x">
        <Header />
        <HeaderSection page="status" data={StatusData.header} />

        <section className="x-asset-status">
          {showError ? (
            <ErrorContent data={StatusData} assets={combinedAssets} />
          ) : loaded ? (
            <ContentSection
              data={StatusData}
              currentStatusItems={[...statusMessagesManual, ...statusMessagesAuto]}
              assets={combinedAssets}
              count={count.all}
              exchangeDisabled={exchangeDisabledInJurisdiction}
              pastStatusItems={pastStatusMessagesManual}
              activeTabIndex={activeTabIndex}
            />
          ) : (
            <LoadingContent />
          )}
        </section>

        <Footer />
      </main>
    </div>
  )
}

export default StatusPage

// <head> component:
export function Head() {
  return <PageHead page="status" nocache />
}
