// dependencies.
import React from 'react'
import { format } from 'date-fns'
// components.
import Heading from 'src/components/heading/Heading'
import StatusItem from 'src/components/pages/status/StatusItem'

// Helpers:
const newDate = new Date()

const date = format(newDate, 'MMMM d, yyyy')
const dateExpand = format(newDate, 'EEEE, h:mm a')

// Main component:
const StatusList = ({ items, itemsUk, data, isUk = false }) => {
  /* default message if there are no items */
  const noStatusData = {
    issues: false,
    heading: data.none.heading,
    description: data.none.description,
    date,
    dateExpand,
  }

  /* prevent from crashing if there are no items */
  const hasItems = isUk ? !!itemsUk.length : !!items.length

  /* Return content */
  return (
    <>
      <Heading type="h2" page="status" section="status" className="x-h2" />
      {hasItems ? (
        isUk ? (
          itemsUk.map((status, s) => <StatusItem key={s} data={status} />)
        ) : (
          items.map((status, s) => <StatusItem key={s} data={status} />)
        )
      ) : (
        <StatusItem data={noStatusData} />
      )}
    </>
  )
}

export default StatusList
