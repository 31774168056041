// dependencies.
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
// utils.
import { useWindowEvent } from 'src/js/utils/hooks'

// Styles & Images:
import 'src/components/pages/status/scss/ContentTabs.scss'

// Helpers:
const contentTabs = [
  {
    name: 'Tab 1',
    count: 1,
    path: '#tab-1',
  },
]

// Main component
const ContentTabs = ({ items = contentTabs, activeIndex = 0, onChange }) => {
  const [activeTabIndex, setActiveTab] = useState(activeIndex)
  const [activeTabIndicator, setActiveTabIndicator] = useState({})

  // set indicator width and left offset
  const tabIndicatorSize = () => {
    const activeTab = document.querySelector('.x__content-tabs__tab--active')

    setActiveTabIndicator({
      left: activeTab.offsetLeft,
      width: activeTab.offsetWidth,
    })
  }
  // set and update indicator position
  useWindowEvent('resize', tabIndicatorSize)
  useEffect(() => tabIndicatorSize(), [activeTabIndex])

  // broadcast active index
  useEffect(() => onChange(activeTabIndex), [activeTabIndex])

  if (!items.length) return null

  return (
    <div className="x__content-tabs">
      <div className="x__content-tabs__wrapper">
        {items.map((item, i) => {
          return (
            <a
              className={classNames('x__content-tabs__tab', {
                'x__content-tabs__tab--active': i === activeTabIndex,
              })}
              key={i}
              href={item.path}
              onClick={() => setActiveTab(i)}
            >
              {item.name}

              {!!item.count && <span>({item.count})</span>}
            </a>
          )
        })}
      </div>

      <span className="x__content-tabs__indicator" style={activeTabIndicator} />
    </div>
  )
}

export default ContentTabs
