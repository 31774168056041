import { every, find } from 'lodash'

import stringToHTML from 'src/js/utils/stringToHTML'
import makeRequest from 'src/js/utils/make-request'

import { HELPERS_URL } from 'src/constants'

const getAssetNameUsingSymbol = (assetsData, symbol) => {
  const asset = find(assetsData, (asset) => asset.ticker === symbol)
  return asset ? asset.name : null
}

const fetchManuallySetData = async (assetsData) => {
  try {
    const body = await makeRequest(`${HELPERS_URL}/helpscout/article/1787`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    })

    const html = stringToHTML(body.article.text)

    // extract status
    const statusMessagesHtml = [...html.querySelectorAll('table')[0].querySelectorAll('tbody tr')]

    const statusMessagesManual = statusMessagesHtml
      .map((el, index) => {
        const row = [...el.querySelectorAll('td')]

        return {
          issues: true,
          date: row[0].textContent.trim(),
          dateExpand: row[1].textContent.trim(),
          heading: row[2].innerHTML,
          subheading: row[3].innerHTML,
          description: row[4].innerHTML,
          aboutExchange: row[5].textContent.trim() === 'y',
        }
      })
      // Do not display if date is not set. Allows CSEs to keep empty row when there are no messages.
      .filter(({ date }) => !!date)

    // extract past status
    const pastStatusMessagesHtml = [
      ...html.querySelectorAll('table')[2].querySelectorAll('tbody tr'),
    ]

    const pastStatusMessagesManual = pastStatusMessagesHtml.map((el, index) => {
      const row = [...el.querySelectorAll('td')]
      const rowData = {
        date: row[0].textContent.trim(),
        dateExpand: row[1].textContent.trim(),
        description: row[2].innerHTML,
      }

      /*
       * if a string in the format `BTC, ETC` is provided,
       * set assetsArray to render a list of assets with icons
       * otherwise set assetsString to render the string as-is
       */
      const assetsString = row[3].textContent.trim()
      const assetsArray = assetsString.split(',').map((str) => str.trim())
      const assetNames = assetsArray.map((str) => getAssetNameUsingSymbol(assetsData, str))

      if (every(assetNames, Boolean)) {
        rowData.assetsArray = assetsArray
      } else {
        rowData.assetsString = assetsString
      }

      return rowData
    })

    // extract assets status
    const assetStatusesHtml = [...html.querySelectorAll('table')[1].querySelectorAll('tbody tr')]

    const assetStatusesManual = assetStatusesHtml
      .map((el, index) => {
        const row = [...el.querySelectorAll('td')]

        const symbol = row[0].textContent.trim()
        if (symbol === '') return null

        return {
          symbol,
          func: row[1].textContent.trim(),
          color: row[2].textContent.trim(),
          tooltipText: row[3].textContent.trim(),
          details: row[4].textContent.trim(),
        }
      })
      .filter((el) => !!el)

    return { statusMessagesManual, pastStatusMessagesManual, assetStatusesManual }
  } catch (err) {
    console.warn('Failed to fetch HS CMS data:', err)

    // Return empty data instead of error if we're unable to fetch or process the HS data for any reason.
    return { statusMessagesManual: [], pastStatusMessagesManual: [], assetStatusesManual: [] }
  }
}

export default fetchManuallySetData
