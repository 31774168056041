function AssetAvailability() {}

AssetAvailability._isError = function (item) {
  if (item.error) return 'error'

  // theoretically an svc having it offline means rate would be 0 as well
  if (item.unavailable && item.rate === 0) return 'unavailable'

  if (item.unavailable) return 'turned off'
  if (item.rate <= 0) return 'rate <= 0'
  if (item.limit <= 0) return 'limit <= 0'
  if (item.maxLimit <= 0) return 'maxLimit <= 0'
  if (item.min > item.limit) return 'min > max'
  if (item.min > item.maxLimit) return 'min > maxLimit'
}

AssetAvailability._formatSides = function (pairStr) {
  const pair = pairStr.split('_')

  const fromSide = pair[0] + '_*'
  const toSide = '*_' + pair[1]

  return { pair: pair, fromSide: fromSide, toSide: toSide }
}

AssetAvailability._countIssues = function (pairs) {
  // pick out the issues and the frequency per asset per side
  const frequency = {}
  const newPairs = []
  let errorCount = 0

  for (let i = 0; i < pairs.length; i++) {
    let item = pairs[i]

    const sides = AssetAvailability._formatSides(item.pair)
    const fromSide = sides.fromSide
    const toSide = sides.toSide

    if (!item.error) {
      // first run, we haven't figured out the errors yet
      const error = AssetAvailability._isError(item)
      if (error) {
        item = JSON.parse(JSON.stringify(item))
        item.error = error
      }
    }

    newPairs.push(item)

    if (frequency[fromSide] === undefined) frequency[fromSide] = { tally: 0, count: 0 }
    if (item.error) {
      frequency[fromSide].tally++
    }
    frequency[fromSide].count++

    if (frequency[toSide] === undefined) frequency[toSide] = { tally: 0, count: 0 }
    if (item.error) {
      frequency[toSide].tally++
    }
    frequency[toSide].count++

    if (item.error) {
      errorCount++
    }
  }

  return { frequency: frequency, pairs: newPairs, errorCount: errorCount }
}

AssetAvailability._isSendSide = function (key) {
  return key.substring(key.length - 2) === '_*'
}

AssetAvailability._sortFrequency = function (frequency) {
  return Object.keys(frequency).sort(function (k1, k2) {
    const tally1 = frequency[k1].tally
    const count1 = frequency[k1].count
    const tally2 = frequency[k2].tally
    const count2 = frequency[k2].count

    const v1 = (tally1 * 100) / count1
    const v2 = (tally2 * 100) / count2

    if (v1 === v2) {
      // from side first
      const sendSide1 = AssetAvailability._isSendSide(k1)
      const sendSide2 = AssetAvailability._isSendSide(k2)

      if (!sendSide1 && sendSide2) return 1
      if (sendSide1 && !sendSide2) return -1

      // alphabetical
      if (k1 < k2) return -1
      if (k1 > k2) return 1
      return 0
    }

    // descending
    return v2 - v1
  })
}

AssetAvailability._checkPairs = function (pairs, ignoredPairs) {
  const causesByAsset = {}

  let counts = AssetAvailability._countIssues(pairs)
  pairs = counts.pairs
  let frequency = counts.frequency
  let errorCount = counts.errorCount

  // cluster the issues by frequency to figure out which side is causing the issue
  while (pairs.length > 0 && errorCount > 0) {
    const sortedFrequency = AssetAvailability._sortFrequency(frequency)

    const assetSide = sortedFrequency.shift()

    const sides = assetSide.split('_')
    const asset = sides[0] !== '*' ? sides[0] : sides[1]
    const direction = sides[0] !== '*' ? 'from' : 'to'

    const causes = []

    // going backwards to be able to splice the array
    for (let i = pairs.length - 1; i >= 0; i--) {
      const item = pairs[i]

      // does this issue match the cluster we are looking at
      const pair = item.pair.split('_')
      if (direction === 'from' && asset !== pair[0]) continue
      if (direction === 'to' && asset !== pair[1]) continue

      if (!ignoredPairs[item.pair] && item.error) {
        // this pair is not ignored, track the issue
        causes.push({ pair: item.pair, error: item.error })
      }
      pairs.splice(i, 1)
    }

    if (causes.length > 0) {
      if (!causesByAsset[asset]) causesByAsset[asset] = {}
      causesByAsset[asset][direction] = causes
    }

    // recount after we spliced the "used" ones
    counts = AssetAvailability._countIssues(pairs)
    pairs = counts.pairs
    frequency = counts.frequency
    errorCount = counts.errorCount
  }

  return causesByAsset
}

export default AssetAvailability
