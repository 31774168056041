import makeRequest from 'src/js/utils/make-request'

import { isDev, EXCHANGE_API_URL } from 'src/constants'

const fetchExchangePairs = async () => {
  try {
    const res = await makeRequest(isDev ? `${EXCHANGE_API_URL}/pairs` : '/exchange-api/pairs', {
      method: 'GET',
    })

    return { exchangePairs: res.data }
  } catch (err) {
    console.warn('Failed to fetch exchange data:', err)
    return { err }
  }
}

export default fetchExchangePairs
