// dependencies.
import React, { useState } from 'react'
// components.
import ContentTabs from 'src/components/pages/status/ContentTabs'
import StatusList from 'src/components/pages/status/StatusList'
import StatusListPast from 'src/components/pages/status/StatusListPast'
import { AssetsTable } from 'src/components/assets'

// Main component:
const ContentSection = ({
  data,
  currentStatusItems,
  assets,
  count,
  columns,
  pastStatusItems,
  activeTabIndex = 0,
  exchangeDisabled = false,
  isUk = false,
}) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex)

  // create tab headings.
  const contentTabs = [
    {
      name: 'Top Asset List',
      path: '#supported-assets',
    },
    {
      name: 'Crypto Status',
      path: '#crypto-status',
      count: currentStatusItems.length,
    },
  ]

  // filter and prepare Uk messages.
  const IS_UK_PREFIX = '#UKONLY#'

  const filterUkMessages = (items = []) => {
    if (!items || !items.length) return []

    return items
      .map((item) => {
        const checkIsUk =
          item.heading?.slice(0, IS_UK_PREFIX.length) === IS_UK_PREFIX ||
          item.heading?.slice(6, IS_UK_PREFIX.length + 6) === IS_UK_PREFIX

        if (checkIsUk) return null
        return item
      })
      .filter((el) => el !== null && el !== undefined)
  }

  const prepareUkMessages = (items = []) => {
    if (!items || !items.length) return []

    return items.map((item) => {
      const checkIsUk =
        item.heading?.slice(0, IS_UK_PREFIX.length) === IS_UK_PREFIX ||
        item.heading?.slice(6, IS_UK_PREFIX.length) === IS_UK_PREFIX

      if (checkIsUk)
        return {
          ...item,
          heading: item.heading?.replace(IS_UK_PREFIX, ''),
        }
      return item
    })
  }

  return (
    <>
      <ContentTabs items={contentTabs} activeIndex={activeTab} onChange={setActiveTab} />

      <div className="x__width">
        {activeTab === 0 && (
          <AssetsTable
            data={data.assets}
            assets={assets}
            columns={columns}
            count={count}
            exchangeDisabled={exchangeDisabled}
          />
        )}
        {activeTab === 1 && (
          <>
            <StatusList
              data={data.status}
              isUk={isUk}
              items={filterUkMessages(currentStatusItems)}
              itemsUk={prepareUkMessages(currentStatusItems)}
            />
            <StatusListPast
              assets={assets}
              data={data.pastStatus}
              isUk={isUk}
              items={filterUkMessages(pastStatusItems)}
              itemsUk={prepareUkMessages(pastStatusItems)}
            />
          </>
        )}
      </div>
    </>
  )
}

export default ContentSection
