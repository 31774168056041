import React from 'react'
import PropTypes from 'prop-types'

// Main component:
const StatusItemSmall = ({ assets, data }) => {
  const getAssetName = (symbol) => {
    /* eslint-disable no-unused-vars */
    for (const data of assets) {
      if (data.symbol === symbol) return data.name
    }
    return null
  }

  return (
    <div className="row x-current-status">
      <div className="col-12 col-md-2">
        <span className="x-current-status__date1">{data.date}</span>
        <span className="x-current-status__date2">{data.dateExpand}</span>
      </div>
      <div className="col-12 col-md-3">
        {data.assetsArray &&
          data.assetsArray.map((symbol) => {
            return (
              <div key={symbol} className="x-current-status__coin">
                <img src={`/img/logos/${symbol}.svg`} alt={`${getAssetName(symbol)} (${symbol})`} />
                <span className="x-current-status__coin-name">
                  {getAssetName(symbol)}{' '}
                  <span className="x-current-status__coin-abbr">({symbol})</span>
                </span>
              </div>
            )
          })}

        {data.assetsString && (
          <span className="x-current-status__coin-name">{data.assetsString}</span>
        )}
      </div>
      <div className="col-12 col-md-7">
        <div
          className="x-current-status__txt1"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>
    </div>
  )
}

// Main component props:
StatusItemSmall.propTypes = {
  assets: PropTypes.array,
  data: PropTypes.object,
}

StatusItemSmall.defaultProps = {}

export default StatusItemSmall
