import React from 'react'

// Styles & Images:
import 'src/components/pages/status/scss/LoadingContent.scss'

// Partials:
const Image = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
        />
      </filter>
    </defs>
  </svg>
)

// Main component:
const LoadingContent = () => (
  <div className="x__width">
    <div className="x__status__loading-outer">
      <div className="x__status__loading">
        <div className="x__status__loading-container">
          <div className="dot dot-1" />
          <div className="dot dot-2" />
          <div className="dot dot-3" />
        </div>

        <Image />
      </div>
    </div>
  </div>
)

export default LoadingContent
