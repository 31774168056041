import { find } from 'lodash'

import AssetAvailability from 'src/js/status/asset-availability'
import StatusData from 'src/data/pages/Contents/status.json'

const statusTemplates = StatusData.assets.statusTemplates

const getErrorCount = (errors) => {
  let count = 0
  try {
    if (errors.from.length > 0) {
      count += errors.from.length
    }
  } catch (err) {}

  try {
    if (errors.to.length) {
      count += errors.to.length
    }
  } catch (err) {}

  return count
}

const getAffectedAssets = ({ from = [], to = [] }) => {
  const assets = []

  for (const error of to) {
    const pairString = error.pair
    const indexOfDash = pairString.indexOf('_')
    assets.push(pairString.substring(0, indexOfDash))
  }

  for (const error of from) {
    const pairString = error.pair
    const indexOfDash = pairString.indexOf('_')
    assets.push(pairString.substring(indexOfDash + 1))
  }

  return assets
}

const pairsDisabledInJurisdiction = (pairs) => {
  return !find(pairs, (pair) => pair.details !== 'jurisdiction')
}

const processExchangeData = ({ pairs, assets }) => {
  let exchangeDisabledInJurisdiction = false
  const exchangeData = {}
  const autoMessagesData = {
    unavailable: [],
    limited: [],
  }

  try {
    // return early if exchange is completely disabled due to jurisdiction
    exchangeDisabledInJurisdiction = pairsDisabledInJurisdiction(pairs)
    if (exchangeDisabledInJurisdiction) {
      return { exchangeDisabledInJurisdiction, exchangeData, autoMessagesData }
    }

    const allErrors = AssetAvailability._checkPairs(pairs, {})

    const assetsSorted = assets.sort((a, b) => {
      return (b.marketcap || 0) - (a.marketcap || 0)
    })
    const crucialAssets = assetsSorted
      .slice(0, 5)
      .map((asset) => asset.exchangeSymbol || asset.symbol)
    const topAssets = assetsSorted.slice(0, 20).map((asset) => asset.exchangeSymbol || asset.symbol)
    const bottomAssets = assetsSorted
      .slice(-30)
      .map((asset) => asset.exchangeSymbol || asset.symbol)

    for (const asset of assets) {
      // skip if not exchangeable
      if (!asset.exchange) continue

      const symbol = asset.exchangeSymbol || asset.symbol

      // if the server returns no data for an exchangeable asset, consider it unavailable in jurisdiction
      const assetPairs = pairs.filter(
        (pair) => pair.pair.startsWith(`${symbol}_`) || pair.pair.endsWith(`_${symbol}`)
      )
      if (!assetPairs.length) {
        exchangeData[symbol] = statusTemplates.jurisdiction
        continue
      }

      const assetErrors = allErrors[symbol]
      if (!assetErrors) {
        exchangeData[symbol] = statusTemplates.available
        continue
      }

      const isTopAsset = topAssets.includes(symbol)
      const isBottomAsset = bottomAssets.includes(symbol)
      const errorsCount = getErrorCount(assetErrors)

      const assetPairsDisabledInJurisdiction = pairsDisabledInJurisdiction(assetPairs)
      if (assetPairsDisabledInJurisdiction) {
        exchangeData[symbol] = statusTemplates.jurisdiction
        continue
      }

      const errorRatio = errorsCount / assetPairs.length

      let statusKey = 'available'

      if (errorRatio > 0.4) {
        statusKey = 'limited'
      }

      /*
       * For the top 20 assets
       * If the ability to exchange with any of the top 5 assets is affected then show limited availability.
       */
      if (isTopAsset) {
        const affectedAssets = getAffectedAssets(assetErrors)
        for (const affectedAsset of affectedAssets) {
          if (crucialAssets.includes(affectedAsset)) statusKey = 'limited'
        }
      }

      if (errorRatio > 0.8) {
        statusKey = 'unavailable'
      }

      exchangeData[symbol] = statusTemplates[statusKey]

      /*
       * For the bottom 30 assets
       * Do not show top-level banners unless ~100% of pairs are unavailable, in order to reduce noise.
       */
      if (['limited', 'unavailable'].includes(statusKey) && (!isBottomAsset || errorRatio > 0.98)) {
        autoMessagesData[statusKey].push(asset)
      }
    }
  } catch (err) {
    console.warn('Failed to process exchange data:', err)
  }

  return { exchangeDisabledInJurisdiction, exchangeData, autoMessagesData }
}

export default processExchangeData
