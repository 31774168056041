// dependencies.
import React from 'react'
// utils.
import { useRellax } from 'js/utils/hooks'

// Styles & Images:
import 'src/components/pages/status/scss/HeaderSection.scss'

// Main component:
const HeaderSection = ({ data }) => {
  useRellax({ className: 'status-rellax' })

  return (
    <header className="x__status__header">
      <div className="x__status__header-bg-container status-rellax" data-rellax-speed="-4">
        <div />
      </div>

      <div className="x__status__header-content">
        <div className="x__status__header-txt-container x__width">
          <h1 className="x__status__header-heading" dangerouslySetInnerHTML={{ __html: data.h1 }} />
        </div>
      </div>
    </header>
  )
}

export default HeaderSection
