import React from 'react'
import PropTypes from 'prop-types'

import { AssetsTable } from 'src/components/assets'

// Main component:
const ErrorContent = ({ data, assets }) => (
  <div className="x__width">
    <p dangerouslySetInnerHTML={{ __html: data.status.error }} />

    <AssetsTable data={data.assets} count={assets.length} disabled />
  </div>
)

// Main component props:
ErrorContent.propTypes = {
  data: PropTypes.object,
  assets: PropTypes.array,
}

export default ErrorContent
