import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Main component:
const StatusItem = ({ data }) => {
  const { issues, date, dateExpand, heading, subheading, description } = data
  const noDate = !date && !dateExpand

  return (
    <div className="row x-current-status-top">
      <div
        className={classNames('col-12', {
          'col-lg-1': noDate,
          'col-lg-4': !noDate,
        })}
      >
        <img
          className="x-current-status-top__icon"
          src={`/status/images/icon-${issues ? 'warning' : 'success'}.svg`}
        />
        <span className="x-current-status-top__date1">{date}</span>
        <span className="x-current-status-top__date2">{dateExpand}</span>
      </div>
      <div
        className={classNames('col-12', {
          'col-lg-11': noDate,
          'col-lg-8': !noDate,
        })}
      >
        {heading && (
          <p className="x-current-status-top__txt1" dangerouslySetInnerHTML={{ __html: heading }} />
        )}
        {subheading && (
          <div
            className="x-current-status-top__txt2"
            dangerouslySetInnerHTML={{ __html: subheading }}
          />
        )}
        <div
          className="x-current-status-top__txt3"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}

// Main component props:
StatusItem.propTypes = {
  data: PropTypes.object,
}

StatusItem.defaultProps = {}

export default StatusItem
